<template>
  <view-item title="综合查询">
    <template #operation>
      <en-button type="primary" @click="operation.export.click">导出</en-button>
    </template>
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="预约项目" name="reservation" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SRSVMT"
                    :height="height"
                    :data="reservation.data"
                    :method="reservation.get"
                    :loading="reservation.loading"
                    pagination
                    :paging="reservation.paging"
                    :config="reservation.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="工单质检" name="complex" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :model="complex.query.data" :method="complex.get">
                <en-form-item label="状态">
                  <select-maintain
                    v-model="complex.query.data.status"
                    :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVCSTAT']) }"
                    :props="{ label: 'message', value: 'code' }"
                    class="w-full"
                  ></select-maintain>
                </en-form-item>

                <en-form-item label="配件名称">
                  <en-input v-model="complex.query.data.goodsName"></en-input>
                </en-form-item>
                <en-form-item label="配件编码">
                  <en-input v-model="complex.query.data.goodsSerialNo"></en-input>
                </en-form-item>

                <en-form-item label="OE号">
                  <en-input v-model="complex.query.data.oem"></en-input>
                </en-form-item>
              </form-query>
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVCINSP"
                    :height="height"
                    :data="complex.data"
                    :method="complex.get"
                    :loading="complex.loading"
                    pagination
                    :paging="complex.paging"
                    :config="complex.config"
                  >
                    <template #SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      <en-button type="primary" link @click="complex.operation.serialNo.click(row)">{{ row.serialNo }}</en-button>
                    </template>
                    <template #TYPE="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      {{ row.code === 'Y' ? '快速工单' : '普通工单' }}
                    </template>
                    <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      {{ row.vehicle?.vehicleSpec.join(',') }}
                    </template>
                    <template #MAINTENANCE="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      {{ row.maintenances.map((item) => item.name).join(',') }}
                    </template>
                    <template #DESCRIPTION="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
                      {{ row.descriptions.join(',') }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="估价项目" name="quotationMaintenance" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVSQTMQRFD"
                    :height="height"
                    :data="quotationMaintenance.data"
                    :method="quotationMaintenance.get"
                    :loading="quotationMaintenance.loading"
                    pagination
                    :paging="quotationMaintenance.paging"
                    show-summary
                    :summary-method="quotationMaintenance.summaryMethod"
                    :config="quotationMaintenance.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="估价配件" name="quotationGoods" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="SVSQTGQRFD"
                    :height="height"
                    :data="quotationGoods.data"
                    :method="quotationGoods.get"
                    :loading="quotationGoods.loading"
                    pagination
                    :paging="quotationGoods.paging"
                    show-summary
                    :summary-method="quotationGoods.summaryMethod"
                    :config="quotationGoods.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
            <en-tab-pane label="保单项目" name="insuranceMaintenance" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    code="PLCINSQRFD"
                    :height="height"
                    :data="insuranceMaintenance.data"
                    :method="insuranceMaintenance.get"
                    :loading="insuranceMaintenance.loading"
                    pagination
                    :paging="insuranceMaintenance.paging"
                    show-summary
                    :summary-method="insuranceMaintenance.summaryMethod"
                    :config="insuranceMaintenance.config"
                  ></table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
import { type TableColumnCtx } from '@enocloud/components'
export default factory({
  config: {
    children: {
      tabs: {
        active: 'reservation'
      },
      reservation: {
        query: {
          data: {
            reservationStatus: '',
            status: '',
            goodsSerialNo: '',
            oem: '',
            goodsName: '',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/reservation/history/maintenance',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.reservation.query.data
            }
          }
        },
        config: {
          NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceName'
              }
            }
          },
          BRANCH: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'reservationStatus',
                props: {
                  options: [
                    { message: '已确认', code: 'F' },
                    { message: '未确认', code: 'P' },
                    { message: '已关闭', code: 'C' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RESERVATION_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'reservationSerialNo'
              }
            }
          },
          RESERVATION_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['startReservationDate', 'endReservationDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customerName'
              }
            }
          },
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          SOURCE: {
            header: {
              filter: {
                type: 'select',
                field: 'source',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['SVRVSRC']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startOrderDate', 'endOrderDate'],
                props: { type: 'daterange' }
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          }
        }
      },
      complex: {
        operation: {
          children: {
            serialNo:{
              click(row: EnocloudServiceDefinitions['ServiceDto']) {
                this.router.push('/query/workorder', (vm) => {
              vm.tabs.active = 'amount'
              vm.amount.query.data.advisorId= row.advisor?.id
              // vm.amount.query.data.status = 'PD'
              // vm.amount.query.data.settlementProgress = 'A'
              vm.amount.get()
           
            })
          
            }
            }
           
          }
        },
        query: {
          data: {
            status: '',
            goodsSerialNo: '',
            oem: '',
            goodsName: '',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/complex',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.complex.query.data
            }
          }
        },
        config: {
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'vehicle.fuelCategory',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },

          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          MAINTENANCE: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenance'
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customerName'
              }
            }
          },

          ONTIME: {
            header: {
              filter: {
                type: 'select',
                field: 'onTime',

                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['FLAG']
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SENDER: {
            header: {
              filter: {
                type: 'text',
                field: 'sender'
              }
            }
          },
          SENDER_TELEPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'senderTelephone'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          VIN: {
            header: {
              filter: {
                type: 'text',
                field: 'vin'
              }
            }
          },
          SERVICE_CATEGORY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          QUALITY_INSPECTOR_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'qualityInspectorName'
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          EXPECTED_COMPLETION_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['expectedCompletionStartDate', 'expectedCompletionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          QUALITY_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['qualityStartDate', 'qualityEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          ACTUAL_COMPLETION_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['completionStartDate', 'completionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          OUT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['outDateStartDate', 'outDateEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          VEHICLE_PURCHASING_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehiclePurchasingStartDate', 'vehiclePurchasingEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          VEHICLE_ANNUAL_INSPECTION_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehicleAnnualInspectionStartDate', 'vehicleAnnualInspectionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          VEHICLE_COMPULSORY_INSURANCE_END_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['vehicleInsuranceStartDate', 'vehicleInsuranceEndDate'],
                props: { type: 'daterange' }
              }
            }
          }
        }
      },
      quotationMaintenance: {
        query: {
          data: {
            reservationStatus: '',
            status: '',
            goodsSerialNo: '',
            oem: '',
            goodsName: '',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/quotation/maintenance/query',
            summary: 'GET /enocloud/service/quotation/maintenance/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.quotationMaintenance.query.data
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['laborHour', 'amountBeforeDiscount', 'amount'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customerName'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          SERVICE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceSerialNo'
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['preparedStartDate', 'preparedEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          MAINTENANCE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'maintenanceName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/maintenance',
                    params: (params, value) => (params.payload = { name: value, i })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['CLKTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'salesmanName'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceComment'
              }
            }
          },
          CUSTOMER_CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },

          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          }
        }
      },
      quotationGoods: {
        query: {
          data: {
            reservationStatus: '',
            status: '',
            goodsSerialNo: '',
            oem: '',
            goodsName: '',
            branchIds: []
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/quotation/goods/query',
            summary: 'GET /enocloud/service/quotation/goods/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.quotationGoods.query.data
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['amountBeforeDiscount', 'amount'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          FUEL_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'fuelCategory',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['FLCTGY'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          BRANCH_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'customerName'
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serialNo'
              }
            }
          },
          SERVICE_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceSerialNo'
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['preparedStartDate', 'preparedEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          MAINTENANCE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'maintenanceName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/maintenance',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          SALESMAN_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'salesmanType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['CLKTYPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'salesmanName'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'maintenanceComment'
              }
            }
          },
          CUSTOMER_CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },

          GOODS_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'goodsSerialNo'
              }
            }
          }
        }
      },
      insuranceMaintenance: {
        query: {
          data: { reservationStatus: '', status: '', goodsSerialNo: '', oem: '', goodsName: '', branchIds: [] }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/policy/insurance/query',
            summary: 'GET /enocloud/policy/insurance/summary',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.insuranceMaintenance.query.data
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['price', 'amount', 'rebateAmount'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          OWNER_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'ownerName'
              }
            }
          },

          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startPolicyDate', 'endPolicyDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PLATE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'plateNo'
              }
            }
          },
          POLICY_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'policySerialNo'
              }
            }
          },
          COMMERCIAL_INSURANCE_COMPANY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'commercialInsuranceCompanyId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { type: 'I', rsexp: 'id,name', pagingEnabled: false }
                }
              }
            }
          },
          COMPULSORY_INSURANCE_COMPANY_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'compulsoryInsuranceCompanyId',
                label: 'name',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  allowCreate: true,
                  props: { type: 'I', rsexp: 'id,name', pagingEnabled: false }
                }
              }
            }
          },
          INSURANCE_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'insuranceName'
              }
            }
          },
          BRANCH_SHORT_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'branchIds',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/branch',
                    params: (params, value) => (params.payload = { quickSearch: value })
                  },
                  remote: true,
                  props: { label: 'shortName', value: 'id' }
                }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'type',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['INSRCTYPE']
                    }
                  },
                  allowCreate: true,
                  remote: true,
                  multiple: true,
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          OWNER_CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'vehicleSpec'
              }
            }
          },
          SALESMAN_NAME: {
            header: {
              filter: {
                type: 'text',
                field: 'salesmanName'
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'preparedBy'
              }
            }
          }
        }
      },
      operation: {
        children: {
          export: {
            ajax: {
              reservation: {
                action: 'GET /enocloud/service/reservation/maintenance/export',
                params(params) {
                  params.payload = this.reservation.query.data
                }
              },
              complex: {
                action: 'GET /enocloud/service/query/export',
                params(params) {
                  params.payload = this.complex.query.data
                }
              },
              quotationMaintenance: {
                action: 'GET /enocloud/service/quotation/maintenance/export',
                params(params) {
                  params.payload = this.quotationMaintenance.query.data
                }
              },
              quotationGoods: {
                action: 'GET /enocloud/service/quotation/goods/export',
                params(params) {
                  params.payload = this.quotationGoods.query.data
                }
              },
              insuranceMaintenance: {
                action: 'GET /enocloud/policy/insurance/export',
                params(params) {
                  params.payload = this.insuranceMaintenance.query.data
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'reservation':
                  this.operation.export.reservation()
                  this.store.openDownload()
                  break
                case 'complex':
                  this.operation.export.complex()
                  this.store.openDownload()
                  break
                case 'quotationMaintenance':
                  this.operation.export.quotationMaintenance()
                  this.store.openDownload()
                  break
                case 'quotationGoods':
                  this.operation.export.quotationGoods()
                  this.store.openDownload()
                  break
                case 'insuranceMaintenance':
                  this.operation.export.insuranceMaintenance()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.reservation.get()
    this.complex.get()
    this.quotationMaintenance.get()
    this.quotationGoods.get()
    this.insuranceMaintenance.get()
  }
})
</script>
